<template>
  <div class="page-register">
    <div class="navbar-pad"></div>
    <div class="row container mx-auto align-self-stretch">
      <div
        class="col-12 col-lg-6 d-none d-lg-flex justify-content-center align-items-center"
      >
        <img
          src="@/assets/images/poster-default.jpg"
          class="h-25 w-auto rounded-circle shadow-sm"
        />
      </div>
      <div class="col-12 col-lg-6 py-5">
        <div class="h-100 d-flex justify-content-center align-items-center">
          <div
            class="auth-form w-100 shadow rounded"
            :class="{
              'auth-crt': $route.name == 'SignUp-CRT',
            }"
          >
            <p class="fs-6" v-if="$route.name == 'SignUp-CRT'">
              Become a
              <span class="fw-medium text-info">
                JAZRO Certified Robotic Trainer
              </span>
            </p>
            <p class="fs-6 fw-medium" v-else>
              Welcome to JAZRO
              <span class="text-nowrap">e-Learning Portal</span>
            </p>
            <p class="mb-5">Register with your email</p>
            <span class="text-danger small" v-if="error.name">
              <i class="fas fa-exclamation-circle mx-1" />
              Full name is required
            </span>
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control text-light"
                id="floatingName"
                placeholder="Ali bin Ahmad"
                v-model="name"
                :class="{ 'is-invalid': error.name }"
                :disabled="isLoading"
                @keydown.enter="doSignUp()"
              />
              <label for="floatingName">
                <i class="fas fa-user-circle me-2 text-muted" />
                Full Name
              </label>
            </div>
            <span class="text-danger small" v-if="error.email">
              <i class="fas fa-exclamation-circle mx-1" />
              Invalid email address
            </span>
            <div class="form-floating mb-3">
              <input
                type="email"
                class="form-control text-light"
                id="floatingEmail"
                placeholder="name@example.com"
                v-model="email"
                :class="{ 'is-invalid': error.email }"
                :disabled="isLoading"
                @keydown.enter="doSignUp()"
              />
              <label for="floatingEmail">
                <i class="fas fa-at me-2 text-muted" />
                Email address
              </label>
            </div>
            <span class="text-danger small" v-if="error.phone">
              <i class="fas fa-exclamation-circle mx-1" />
              Invalid phone number
            </span>
            <div class="form-floating mb-3">
              <input
                type="tel"
                class="form-control text-light"
                id="floatingPhone"
                placeholder="0123456789"
                v-model="phone"
                @input="phone = phone.replace(/[^\d,]/g, '')"
                :class="{ 'is-invalid': error.phone }"
                :disabled="isLoading"
                @keydown.enter="doSignUp()"
              />
              <label for="floatingPhone">
                <i class="fas fa-mobile-alt me-2 text-muted" />
                Phone Number
              </label>
            </div>
            <span class="text-danger small" v-if="error.password">
              <i class="fas fa-exclamation-circle mx-1" />
              Invalid password
            </span>
            <div class="form-floating">
              <input
                :type="showPasswordText ? 'text' : 'password'"
                class="form-control text-light"
                id="floatingPassword"
                placeholder="Password"
                v-model="password"
                :class="{ 'is-invalid': error.password }"
                :disabled="isLoading"
                @keydown.enter="doSignUp()"
              />
              <label for="floatingPassword" class="w-100">
                <i class="fas fa-lock me-2 text-muted" />
                Password
              </label>
              <button
                class="btn btn-link position-absolute top-0 end-0 h-100 text-muted"
                @click="showPasswordText = !showPasswordText"
              >
                <i
                  class="far"
                  :class="showPasswordText ? 'fa-eye' : 'fa-eye-slash'"
                />
              </button>
            </div>
            <ul class="small text-muted mt-2 mb-4">
              <li>Password must be at least 7 characters long</li>
              <li>Must contains at least 1 number</li>
              <li>Must contains at least 1 letter</li>
            </ul>
            <div
              class="mb-3 alert alert-danger text-dark small"
              v-if="errorMessage"
            >
              <template v-for="error in errorMessage">
                <p class="fw-medium" :key="`error-type-${error.id}`">
                  <i class="fas fa-exclamation-triangle me-2 text-danger" />
                  <span v-if="error.id == 'Auth.form.error.email.taken'">
                    Email already taken
                  </span>
                  <span v-else>Oops, there is an error</span>
                </p>
                <p :key="`error-message-${error.id}`">
                  <span v-if="error.id == 'Auth.form.error.email.taken'">
                    The email you entered already exists in our portal.<br />
                    Please use another email or
                    <router-link
                      :to="{ name: 'SignIn', query: { returnUrl: returnUrl } }"
                      >sign in to your account</router-link
                    >
                  </span>
                  <span v-else>
                    {{ error.message }}
                  </span>
                </p>
              </template>
            </div>
            <button
              class="btn btn-lg w-100 mb-4"
              :class="actionButtonClass"
              @click="doSignUp()"
              :disabled="isLoading"
            >
              <small v-if="!isLoading">{{ actionButtonText }}</small>
              <small v-else>
                <span class="spinner-border spinner-border-sm me-2"></span>
                Registering
              </small>
            </button>
            <p class="mb-0 fw-medium small">Already have an account?</p>
            <router-link
              :to="{ name: 'SignIn', query: { returnUrl: returnUrl } }"
              class="text-decoration-none text-secondary small"
            >
              Sign in here.
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
      isLoading: false,
      returnUrl: null,
      name: "",
      email: "",
      password: "",
      phone: "",
      error: {
        email: false,
        password: false,
        name: false,
        phone: false,
      },
      errorMessage: null,
      actionButtonText: "Register",
      actionButtonClass: "btn-dark",
      showPasswordText: false,
    };
  },
  watch: {
    email: {
      handler() {
        if (this.email) {
          this.email = this.email.replace(/\s+/g, "");
        }
      },
      immediate: true,
    },
    "$route.name": {
      handler() {
        if (this.$route.name == "SignUp-CRT") {
          this.actionButtonText = "Register as CRT";

          this.actionButtonClass = "btn-info";
        } else {
          this.actionButtonText = "Register";

          this.actionButtonClass = "btn-dark";
        }
      },
      immediate: true,
    },
  },
  methods: {
    async doSignUp() {
      if (this.isLoading) return;

      this.isLoading = true;
      this.errorMessage = null;
      Object.keys(this.error).forEach((v) => (this.error[v] = false));

      this.name = this.name.trim();

      setTimeout(async () => {
        const fieldValidation = {
          email: this.Helper.validateEmail(this.email),
          password: this.Helper.validatePassword(this.password),
          phone: this.Helper.validateTel(this.phone),
          name: this.name.length > 3 ? true : false,
        };

        Object.keys(this.error).forEach((v) => {
          this.error[v] = !fieldValidation[v];
        });

        if (Object.values(this.error).some((invalid) => invalid)) {
          this.isLoading = false;

          return;
        } else {
          try {
            const body = {
              name: this.name.toUpperCase(),
              username: this.email,
              email: this.email,
              password: this.password,
              phone: this.phone,
              country: "Malaysia",
              confirmed: true,
              lastLogin: Date.now(),
              isCRT: false,
            };

            if (this.$route.name == "SignUp-CRT") {
              body.isCRT = true;
            }

            const { data } = await this.API.post("auth/local/register", body);

            if (!data) {
              this.errorMessage = true;
            } else {
              localStorage.setItem("token", data.jwt);

              this.Helper.validateToken();

              window.scrollTo(0, 0);

              Swal.fire({
                title: "<h4>Thank you</h4>",
                html: "<p>You are now a registered member of<br /><span class='text-primary fw-medium'>JAZRO e-Learning Portal</span></p>",
                icon: "success",
                confirmButtonText: "Continue",
              }).finally(() => {
                let returnPath =
                  data.user.role && data.user.role.name == "Admin"
                    ? "/admin"
                    : data.user.isCRT
                    ? "/crt"
                    : "/my-account";

                if (this.returnUrl) {
                  returnPath = this.returnUrl;
                }

                this.$router.push({
                  path: returnPath,
                });
              });
            }
          } catch (err) {
            this.errorMessage = err.response.data.message
              .map((m) => m.messages)
              .flat();
          } finally {
            this.isLoading = false;
          }
        }
      }, 200);
    },
  },
  mounted() {
    if (this.$route && this.$route.query && this.$route.query.returnUrl) {
      this.returnUrl = this.$route.query.returnUrl;
    }
  },
};
</script>